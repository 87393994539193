import { graphql, useStaticQuery } from 'gatsby';
import React, { FC, useContext, useEffect, useRef, useState } from 'react';
import { UiStateContext } from '../context/UiStateContext';
import { useDataLayerPush } from '../hooks/useDataLayerPush';
import { useClientDetailsLazyQuery } from '../types/graphqlHelpers';
import { getCleanPropertyDetails } from '../utils/getCleanPropertyDetails';
import { Kfh_PropertyTypeInterface } from '../types/graphqlTypes';
import { isBrowser, isPropertyPageCheck } from '../utils';

export interface DataLayerWrapperProps {
  children: any;
  data: any;
  serverData: any;
  location: any;
}

export const DataLayerWrapper: FC<DataLayerWrapperProps> = ({ children, data, serverData, location }) => {
  const { pathname } = location;
  const { dataLayerPush } = useDataLayerPush();
  const [clientIp, setClientIp] = useState(undefined);
  const lastPathnameRef = useRef('');
  const pageReferrerRef = useRef<string | null>(null);

  // Get internal IPs
  const {
    kfhKfhSettingsType: { internalIps },
  } = useStaticQuery(graphql`
    query internalIpsQuery {
      kfhKfhSettingsType {
        internalIps: internalCookieStateIps
      }
    }
  `);

  // Get client IP
  const [getClientDetails, { data: clientDetails }] = useClientDetailsLazyQuery();

  useEffect(() => {
    setClientIp(clientDetails?.client.clientDetails?.ipAddress);
  }, [clientDetails]);

  useEffect(() => {
    getClientDetails();
  }, []);

  // Handle page classification
  let targetAudience;
  let targetService;

  if (data?.page && data?.page?.classification) {
    const { audiences, services } = data.page.classification;
    if (audiences && audiences.length > 0) {
      const audienceArray = audiences.map((item) => item);
      targetAudience = audienceArray.join(',');
    }
    if (services && services.length > 0) {
      const servicesArray = services.map((item) => item);
      targetService = servicesArray.join(',');
    }
  }

  // Handle Property details
  let propertyDetails: Kfh_PropertyTypeInterface;
  if (
    data?.property ||
    location?.pathname?.includes('/properties-for-sale/') ||
    location?.pathname?.includes('/commercial-properties') ||
    location?.pathname?.includes('/development-properties') ||
    location?.pathname?.includes('/search-results') ||
    location?.pathname?.includes('/properties-to-rent/')
  ) {
    propertyDetails = data?.property;
  }

  // Content group
  let contentGroup;
  if (data?.area || data?.region || location?.pathname?.includes('/london/')) {
    contentGroup = 'area guides';
  }
  if (
    location?.pathname?.includes('/properties-for-sale/') ||
    location?.pathname?.includes('/search-results') ||
    location?.pathname?.includes('/properties-to-rent/')
  ) {
    contentGroup = 'residential search results';
  }
  if (location?.pathname?.includes('/commercial-properties')) {
    contentGroup = 'commercial properties search results';
  }
  if (location?.pathname?.includes('/development-properties')) {
    contentGroup = 'development properties search results';
  }
  if (location?.pathname?.includes('/new-home-properties')) {
    contentGroup = 'new homes search results';
  }
  if (location?.pathname?.includes('/branches')) {
    contentGroup = 'branch pages';
  }
  if (location?.pathname?.includes('/buyers')) {
    contentGroup = 'buyers pages';
  }
  if (location?.pathname?.includes('/sellers')) {
    contentGroup = 'sellers pages';
  }
  if (location?.pathname?.includes('/tenants')) {
    contentGroup = 'tenants pages';
  }
  if (location?.pathname?.includes('/landlords')) {
    contentGroup = 'landlords pages';
  }
  if (location?.pathname?.includes('/services')) {
    contentGroup = 'services pages';
  }
  if (location?.pathname?.includes('/careers') || location?.pathname?.includes('/jobs')) {
    contentGroup = 'careers pages';
  }
  if (location?.pathname?.includes('/contact')) {
    contentGroup = 'contact pages';
  }
  if (location?.pathname?.includes('/my-kfh') || location?.pathname?.includes('/mykfh-login-and-register')) {
    contentGroup = 'mykfh pages';
  }
  if (location?.pathname === '/') {
    contentGroup = 'home page';
  }
  if (location?.pathname?.includes('/about-kfh')) {
    contentGroup = 'about pages';
  }
  if (data?.property) {
    contentGroup = 'property page';
  }

  // Check if pathname has changed
  const hasPathnameChanged = () => pathname !== lastPathnameRef.current;

  // Handle route change
  useEffect(() => {
    const pageLocation = isBrowser ? window.location.href : '';
    const pageReferrer = isBrowser ? pageReferrerRef.current || document.referrer : '';

    if (clientIp && hasPathnameChanged()) {
      // Update the referrer to the current page for the next route change
      pageReferrerRef.current = pageLocation;
      lastPathnameRef.current = pathname; // Update ref with the new pathname

      setTimeout(() => {
        const isNotFoundPropertyPage = isPropertyPageCheck(pathname as string) && !data?.property;
        if (!isNotFoundPropertyPage) {
          dataLayerPush({
            event: 'gatsby-route-change',
            contentGroup,
            targetAudience,
            targetService,
            propertyDetails: getCleanPropertyDetails(propertyDetails),
            internalTraffic: internalIps.includes(clientIp),
            pageLocation,
            pageReferrer,
          });
        }
      }, 100);
    }
  }, [pathname, clientIp]);

  // Handle blades opening / closing
  const { activeBlade } = useContext(UiStateContext);
  const [lastBladeOpened, setLastBladeOpened] = useState();

  useEffect(() => {
    if (
      activeBlade &&
      activeBlade !== 'propertyCard' &&
      activeBlade !== 'propertyArrangeAViewing' &&
      activeBlade !== 'branch' &&
      activeBlade !== 'branchContact'
    ) {
      dataLayerPush({
        event: 'blade action',
        bladeEvent: 'opened',
        bladeOpen: true,
        bladeEventID: activeBlade,
        bladePropertyDetails: undefined,
        bladeBranchName: undefined,
      });
      setLastBladeOpened(activeBlade);
    } else if (lastBladeOpened) {
      dataLayerPush({
        event: 'blade action',
        bladeEvent: 'closed',
        bladeOpen: false,
        bladeEventID: lastBladeOpened,
        bladePropertyDetails: undefined,
        bladeBranchName: undefined,
      });
    }
  }, [activeBlade]);

  return <>{children}</>;
};
